import { Button, Paper, TextField,  } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { submitNewRoom,  } from '../Redux/actions';
import Swal from 'sweetalert2'



export default function CreateRoom(){
    const dispatch: any = useDispatch()
    const [room, setRoom] = useState({})
    const [img, setImg] = useState([])
    let a = new Array 

    const handleChangeRoom = (e: any)=> {
        setRoom({
            ...room,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmitRoom = async ()=> {     
       await dispatch(submitNewRoom(room))
       .then(()=> {
           Swal.fire('La Base de Datos se ha actualizado con éxito')
           window.location.reload()
       }).catch(()=> {
           Swal.fire({
               icon: 'error', 
               text: 'Ocurio un error en la petición HTTP'
           })
       })
    }

    
    return(
        <div>
            <Paper style={{display: 'grid', marginLeft: '-100%', marginBottom: '-12rem', marginTop: '2em', width: '300%'}}>
                <TextField id="filled-basic" 
                style={{backgroundColor: 'white',}} 
                label="Nombre de la habitación" variant="filled" 
                type='text'
                name='name'
                onChange={handleChangeRoom}
                />
                
               <TextField  
                style={{backgroundColor: 'white', }}      
                id="outlined-multiline-static"
                variant="standard"
                label="Descripción del espacio"
                type='text'      
                name='description' 
                multiline
                rows={4}             
                onChange={handleChangeRoom}      
                /> 

                <TextField id="filled-basic" 
                style={{backgroundColor: 'white'}} 
                label="Precio al público " variant="filled" 
                type='number'                
                name='price'   
                onChange={handleChangeRoom}     
                />                
                 <TextField id="filled-basic" 
                style={{backgroundColor: 'white'}} 
                label="Cantidad de habitaciones disponibles" variant="filled" 
                type='number'
                name='quantity'  
                onChange={handleChangeRoom}      
                />
                <TextField id="filled-basic" 
                style={{backgroundColor: 'white'}} 
                label="Cargar imagenes" variant="filled" 
                type='file'
                name='imagen'  
                onChange={handleChangeRoom}      
                />

                <Button onClick={handleSubmitRoom}> Cargar nuevo espacio o experiencia </Button>

            </Paper>
        </div>
    )
}