import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getAllActivities } from '../Redux/actions';
import Card from "./Card";


export default function Activities(){
    const dispatch: any = useDispatch()
    const navigate = useNavigate()

    // const allActivities = useSelector((state: any)=> state.activities)
    // useEffect(()=> {
    //     dispatch(getAllActivities())
    // }, [dispatch])
    // console.log(allActivities)
    // console.log(allActivities.length)

    const allActivities = [
        {
            "_id": "62ab7614333055a0fd93ddc0",
            "name": "Expedición a grutas",
            "description": "Expedición a zona de grutas, requiere esfuerzo fisico medio",
            "imagen": [
                "https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/grutaLagartijas.jpg?alt=media&token=5879b3ee-af06-41aa-b623-fe3bd9ac937b",
                "https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/gruta.jpg?alt=media&token=2dc8be1f-95ec-41c9-b882-f4fdeae42a9d",
                "https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/gruta2.jpg?alt=media&token=66042eca-2076-4aa5-860b-f1e33411cd54"
            ],
            "price": 350,
            "discount": 0,
            "quantity": 1,
            "__v": 0
        }
    ]
    return(
            <div style={{maxHeight: '90%',  }}>
            <h5> Actividades </h5>        
            { allActivities ? 
            allActivities.map((x: any)=>  <Card
                key={x._id}                 
                name= {x.name}
                imagen= {x.imagen}
                description= {x.description}
                price= {x.price} 
                buttonText= '¡Reserva ahora!'
                buttonFunction = {()=> window.location.href = 'https://wa.me/5212311328239'}
                />                                
            ) 
            : 
            <h5> No hay actividades cargadas </h5>     
        }
        </div>
    )
}