import React, { useEffect, useState } from 'react'
import { Paper, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import './ReservationDetails.css'
import StripeCheckout from 'react-stripe-checkout';
import axios from 'axios';
import { submitMyPersonalReservationToDB } from '../Redux/actions';


export default function ReservationDetails(){    
    const reservations = useSelector((state)=> state.myPersonalReservation)
    const dispatch = useDispatch()

    const grandTotal = reservations.map((x)=> {
        return x.price * x.days
    })

    const roomsId = reservations.map(x=> x.roomsId)
    const rooms = reservations.map(x=> x.rooms)
    const days = reservations.map(x=> x.days)
 
    const dates = reservations.map(x=> x.dates)

    const totalToPay = grandTotal.reduce(((acc, el)=> acc + el), 0)

      const [stripeToken, setStripeToken] = useState(null);
      const onToken = (token) => {
        setStripeToken(token)
    }

    const REACT_BACKEND_URL = `http://localhost:3001`

        useEffect(()=> {
            const myPay = async ()=> {
             axios.post(`${REACT_BACKEND_URL}/api/stripe/create-checkout-session`, {
                   roomId: roomsId,
                   amount: totalToPay, 
                   tokenId: stripeToken,                             
                })
                .then(x=> {
                    console.log('recibiendo datos --> ', x.data)
                    if(x.data.name, x.data.email, dates, rooms, grandTotal, x.data.roomId){
                        dispatch(submitMyPersonalReservationToDB(x.data.name, x.data.email, dates, rooms, grandTotal, x.data.roomId))
                    }                    
                }).then(x=> {
                    console.log('legamos a x ', x)
                })              
            }
            myPay()           
        })

    return(
        <div>
            <Paper className='details'> 
            <h1> Los detalles de tu reservación: </h1>
                       { reservations.map((x)=> {
                           return <div>
                            <h5> { x.rooms } </h5>
                            <span> { x.days } { x.days=== 1 ? 'Noche' : 'Noches' } </span>
                            <span> Total: { x.days * x.price } </span>                              
                           </div>

                       })}
                       <h4> Total a pagar: $ {totalToPay} </h4>

                       
                       { totalToPay > 0 ?    
                            <StripeCheckout
                            token={onToken}
                            stripeKey='pk_test_51KhdWJIZblSL4ZcobEoooQQLwvHJWXmplHAi0KFyRLmRKpPvwMOe1exVHNXiDpptBWniMDVf67OoROvcq6IiI8qd00UtDiuA2n'
                            billingAddress                            
                            description={`El total a pagar es de ${totalToPay}`}
                            amount={totalToPay}
                           > 
                               <Button variant="contained" color="secondary" sx={{ margin: "0.2em" }} > Pago con Tarjeta </Button>
                            </StripeCheckout>
                    : null}
            </Paper>
        </div>
    )
}