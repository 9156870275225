import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './ContactForm.css'
import Swal from 'sweetalert2';
import { Button } from 'react-bootstrap';

export default function ContactEmailJs(){
    const form = useRef();

    const [user, setUser] = useState('')
    const [mail, setmail] = useState('')
    const [text, settext] = useState('')

    const handleChangeUser = (e)=> {
      setUser(...user, e.target.value)
    }

    const handleChangeMail = (e)=> {
      setmail(...mail, e.target.value)
    }

    const handleChangeText = (e)=> {
      settext(...text, e.target.value)
    }

    const sendEmail = (e) => {
      e.preventDefault();
      if(!user || !mail || !text){
        Swal.fire({
          icon: 'error', 
          tittle: 'Todos los campos son requeridos', 
          text: 'Todos los campos son requeridos'
      })
        return console.log('saliendo por falta de ', user, mail, text, );  
      }
      
      emailjs.sendForm('service_yd6lf7p', 'template_ba3yiiu', form.current, 'HltSTQ3wny-c3FmUx')
        .then((result) => {
            //console.log(result.text);
            Swal.fire('Email enviado con exito')
            window.location.reload()
        }, (error) => {
            console.log(error.text);
        });
    };
  
    return (
      <form ref={form} onSubmit={sendEmail} className='form'>
        <label>Nombre</label>
        <input type="text" name="user_name" className='input' onChange={handleChangeUser}/>
        <br/> 
        <label>Email</label>
        <input type="email" name="user_email" className='input'onChange={handleChangeMail}/>
        <br/> 
        <label>Mensaje</label>
        <textarea name="message" className='input'onChange={handleChangeText}/>
        <br/> 
        <input type="submit" value="Enviar" className='boton'/>
        {/* <Button className='boton' onClick={sendEmail}> Enviar </Button> */}
      </form>
    );
}