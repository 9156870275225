import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { adminLogin } from '../Redux/actions';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function Login(){
    const dispatch: any = useDispatch()
    const navigate = useNavigate()

    const myUserAdmin = useSelector((state: any)=> state.isAdmin)
   //console.log(myUserAdmin)
    
    const [admin, setAdmin] = useState({
        email: '',
        password: '', 
    })

    const handleChangeEmail = (e: any)=> {
       
        setAdmin({
            ...admin, 
            email: e.target.value
        })
    }

    const handleChangePassword = (e: any)=> {
        
        setAdmin({
            ...admin, 
            password: e.target.value
        })
    }
   
    const handleSubmitAdminData = async ()=> {   
        dispatch(adminLogin(admin.email, admin.password))
            .then((x:any)=> {
                if(x.payload.data.token){
                    navigate('/admin/controlPanel')
                }
            }).catch(()=> {
                Swal.fire({
                            icon: 'error', 
                            text: 'Contraseña o email invalidos'
                        })
            })
    
    }
    
   

    return(
        <div style={{display: 'grid'}}>
           <h5> Bienvenido al login de administración  </h5> 
           <span> Por favor digita tus credenciales para acceder al panel </span>
          
           <div style={{display: 'grid', border: 'solid 2px black', }}>

            <TextField id="filled-basic" 
            style={{backgroundColor: 'white'}} 
            label="email" variant="filled" 
            type='email'
            name='email'
            onChange={handleChangeEmail}
            />
            <TextField id="filled-basic" 
            style={{backgroundColor: 'white'}} 
            label="Password" variant="filled"
            type='password'
            name='password'
            onChange={handleChangePassword}
            />
            
            <Button variant='contained' color='primary'
            onClick={(handleSubmitAdminData)}
            > Login </Button>
            </div>
        </div>
    )
}