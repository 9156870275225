import React from 'react'
import { Paper } from "@mui/material";
import { Button } from "react-bootstrap";
import Carrousel from "./Carrousel";
import './Card.css'


export default function Card({name, price, description, imagen, buttonText, buttonFunction}){
 
    return(
        
        <Paper className="main-card" style={{backgroundColor: 'rgb(216, 221, 226)', boxShadow: '2px 2px 2px 1px rgba(0.5, 0, 0, 0.5)'}}>        
                <h4> {name} </h4>
                <Carrousel className='carrousel'
                images={imagen}            
                />         
            <div> 
                <span> { description } </span>
                <span> $ { price } </span>
            </div> 

                <Button onClick={buttonFunction}> {buttonText} </Button>
            
        </Paper>
       
    )
}