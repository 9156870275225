import React from 'react'
import {Carousel} from 'react-bootstrap'
import './Carrousel.css'

export default function Carrousel(images){

  const theImages = images.images
  let sum = 1
    return(                    
  <Carousel  >
    { theImages && theImages.length ?
    theImages.map(x=> {
      return  <Carousel.Item key={sum ++}>        
       <img
      className="d-inline-block carrousel-img"
      src= { x }
      alt="Slide"
    />
    </Carousel.Item>
     
    }) : <h1> slider </h1>     
  }
</Carousel>
        
    )
}