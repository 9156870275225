import React from 'react'

export default function Footer(){

    return(
        <div style={{backgroundColor: 'rgb(95, 96, 97)', }}> 

        <span> Plan de Guinea 2022 </span>
            <a href="https://www.facebook.com/PLAN-DE-GUINEA-TURISMO-DE-NATURALEZA-135669523172828"
            style={{margin: '1em'}}
            >
                <img src='https://i.pinimg.com/originals/30/99/af/3099aff4115ee20f43e3cdad04f59c48.png' alt="FB"
                style={{width: '2em'}}
                />
            </a>
            <a href="https://www.instagram.com/plandeguinea/"
              style={{margin: '1em'}}
            >
                <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/768px-Instagram_logo_2016.svg.png' alt="FB"
                style={{width: '2em'}}
                />
            </a>
            <a href="https://wa.me/5212311328239"
              style={{margin: '1em'}}
            >
                <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/WhatsApp_logo-color-vertical.svg/2048px-WhatsApp_logo-color-vertical.svg.png' alt="FB"
                style={{width: '2em'}}
                />
            </a>

            <span style={{fontFamily: 'Edwardian Script ITC', color: 'white'}}> powered by gdlcp </span>
        </div>
    )
}
