import React from 'react'
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './Components/home';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Rooms from './Components/Rooms';
import Login from './Components/LoginAdmin';
import ControlPanel from './Components/ControlPanel';
import CreateRoom from './Components/CreateRoom';
import ReservationMotor from './Components/ReservationMotor';
import ContactForm from './Components/ContactForm.jsx';
import CreateActivity from './Components/CreateActivity';
import Activities from './Components/Activities';
import ContactEmailJs from './Components/ContactEmailJs';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Navbar />

        <Routes>
          <Route index element = { <Home />} /> 
          <Route path='/Espacios' element = { <Rooms /> } />
          <Route path = '/admin' element = { <Login /> } /> 
          <Route path = '/admin/controlPanel' element = { <ControlPanel />} />
          <Route path = '/admin/controlPanel/newRoom' element = { <CreateRoom /> } /> 
          <Route path = '/admin/controlPanel/newActivity' element = { <CreateActivity /> } /> 
          <Route path = '/reservations' element = { <ReservationMotor /> } /> 
          <Route path = '/actividades' element = { <Activities /> } /> 
          <Route path = '/Contacto' element = { <ContactEmailJs /> } />  
        </Routes>

      </header>
      <Footer />
    </div>
  );
}

export default App;
