import React from 'react'
import { Button, makeStyles, Paper } from "@mui/material";
import { useNavigate } from 'react-router-dom';


// const useStyles = makeStyles({

//     main: {
//         border: 'solid 2px black'
//     }
// })

export default function ControlPanel(){
   // const clases = useStyles()
   const navigate = useNavigate()

    return(
        <div>
            
            <Paper style={{border: 'solid 2px black', marginTop: '5rem', height: '6rem'}} >
                <h4> Crear un nuevo espacio aquí: </h4>
                <Button variant="contained" color='primary'
                    onClick={()=> navigate('./newRoom')}
                > Nuevo Espacio </Button>
            </Paper>
        </div>
    )
}