import React, { useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useHref, useNavigate } from 'react-router-dom';
import { getAllRooms } from '../Redux/actions';
import Card from "./Card";

export default function Rooms(){
//     const dispatch: any = useDispatch()
//     const navigate = useNavigate()
//    let allRooms = useSelector((state: any)=> state.rooms)
//     useEffect(()=> {
//        dispatch(getAllRooms())
//     }, [dispatch])
// console.log(allRooms)

    const allRooms = [
        {
            "_id": "6279789842124c6567b88123",
            "name": "Habitación Sencilla",
            "description": "Comfortable habitacion con aire acondicionado ",
            "imagen": [
                "https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/habitacionsencilla.jpeg?alt=media&token=332a7ebd-2c6a-4167-92af-14980840fa0f"
            ],
            "price": 600,
            "discount": 0,
            "quantity": 5,
            "__v": 0
        },
        {
            "_id": "629101ce382193ceac050cbe",
            "name": "Cabaña 1 ",
            "description": "Cabaña con terraza al rio, 100% ecologica ",
            "imagen": [
                "https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/caba%C3%B1a2-segunda.jpeg?alt=media&token=86497e49-e113-4cee-bcf0-7f49890567fb",
                "https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/caba%C3%B1a2.jpeg?alt=media&token=a88497ce-fd43-4158-b069-3271d376d7cb"
            ],
            "price": 650,
            "discount": 0,
            "quantity": 1,
            "__v": 0
        },
        {
            "_id": "62a24fbabb13ba8fc8802541",
            "name": "Cabaña 2 ",
            "description": "Comfortable habiracion con dos camas matrimoniales, terraza al rio, 100% sustentable hecha con bambú ",
            "imagen": [
                "https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/caba%C3%B1a2.jpeg?alt=media&token=a88497ce-fd43-4158-b069-3271d376d7cb",
                "https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/caba%C3%B1a2-segunda.jpeg?alt=media&token=86497e49-e113-4cee-bcf0-7f49890567fb"
            ],
            "price": 900,
            "discount": 0,
            "quantity": 1,
            "__v": 0
        },
        {
            "_id": "62a25082bb13ba8fc8802545",
            "name": "Cabaña 3 ",
            "description": "Comfortable habitación con tres camas matrimoniales, terraza al rio, 100% sustentable hecha con bambú ",
            "imagen": [
                "https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/caba%C3%B1a3pasamanos.jpeg?alt=media&token=03fcad6a-e5b5-41ab-9195-af7810318f3e"
            ],
            "price": 1500,
            "discount": 0,
            "quantity": 1,
            "__v": 0
        },
        {
            "_id": "62a250cfbb13ba8fc880254d",
            "name": "Habitación Doble ",
            "description": "Comfortable habitación con dos camas matrimoniales, aire acondicionado ",
            "imagen": [
                "https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/habitaciondoble.jpeg?alt=media&token=51364fe4-bbc2-4667-94b3-740fe795f383",
                "https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/habitaciondoble-1.jpeg?alt=media&token=b4b8d3e6-bf83-4194-b0fb-31eea09973a5"
            ],
            "price": 900,
            "discount": 0,
            "quantity": 2,
            "__v": 0
        }
    ]
    return(
        <div style={{maxHeight: '90%',  }}>
            <h5> Habitaciones </h5>        
            { allRooms ? 
            allRooms.map((x: any)=>  <Card
                key={x._id}                 
                name= {x.name}
                imagen= {x.imagen}
                description= {x.description}
                price= {x.price} 
                buttonText= '¡Reserva ahora!'
               // buttonFunction = {()=> navigate('/reservations')}
               buttonFunction = {()=> window.location.href = 'https://wa.me/5212311328239'}
                />                 
            ) 
            : 
            <h5> No hay habitaciones cargadas </h5>     
        }
        </div>
    )
}
