import React, { useState } from 'react'
import {Paper, TextField, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { sendNewActivityToAPI } from '../Redux/actions';
import Swal from 'sweetalert2'

export default function CreateActivity(){
    const dispatch: any = useDispatch()

    const [activity, setActivity] = useState({})
    const [image, setImage] = useState({})

    const handleChangeActivity = (e:any)=> {
        setActivity({
            ...activity,
            [e.target.name]: e.target.value,
            image: image
        })
    }

    console.log(activity)
    
    const handleImage = (e: any) => {     
        const newImage = e.target.files[0]     

        setImage({
            ...image, 
            image: URL.createObjectURL(newImage)})   
    }

    const handleSubmitActivity = async()=> {
        await dispatch(sendNewActivityToAPI(activity))
        .then(()=> {
            Swal.fire({
                icon: 'success', 
                title: 'Base de datos actualizada con exito'
            }).then((result: any)=> {
                if(result.isConfirmed){
                    window.location.reload()
                }
            })
        })
    }

    return(
        <div>
             <Paper style={{display: 'grid', marginLeft: '-100%', marginBottom: '-12rem', marginTop: '2em', width: '300%'}}>
                <TextField id="filled-basic" 
                style={{backgroundColor: 'white',}} 
                label="Nombre de la actividad" variant="filled" 
                type='text'
                name='name'
                onChange={handleChangeActivity}
                />
                
               <TextField  
                style={{backgroundColor: 'white', }}      
                id="outlined-multiline-static"
                variant="standard"
                label="Descripción de la actividad"
                type='text'      
                name='description' 
                multiline
                rows={4}             
                onChange={handleChangeActivity}    
                /> 

                <TextField id="filled-basic" 
                style={{backgroundColor: 'white'}} 
                label="Precio al público " variant="filled" 
                type='number'                
                name='price'   
                onChange={handleChangeActivity}     
                />                

                <TextField id="filled-basic" 
                style={{backgroundColor: 'white'}} 
                label="Cargar imagenes" variant="filled" 
                type='file'
                name='imagen'  
                onChange={handleImage}    
                />

                <Button onClick={()=> handleSubmitActivity()}> Cargar nuevo espacio o experiencia </Button>

            </Paper>
        </div>
    )
}