import React from 'react'
import Carrousel from './Carrousel';
import './home.css'
// import pdgAlberca from '../Img/pdgImages/pdgAlberca.jpg'
// import pdgCascada from '../Img/pdgImages/cascadapdg.jpg'
// import rio from '../Img/pdgImages/rio.jpg'
// import rio2 from '../Img/pdgImages/rio2.jpg'
// import rioPdg from '../Img/pdgImages/riopdg.jpg'

export default function Home(){
    
    // const images = [
    //     pdgAlberca, rio, rio2, rioPdg, pdgCascada
    //    ]

    const images = [
        'https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/cascadafrente.jpeg?alt=media&token=fb256077-1492-424b-b418-96f7c35afb72',
        'https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/hotel.jpeg?alt=media&token=d4d88811-81c4-46da-9898-c8b8133e4a5a',
        'https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/pdgResta.jpg?alt=media&token=5577fbe5-a1c8-4a66-9f1e-8e297b84098d',
        'https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/calamardo.jpeg?alt=media&token=29688e59-ca7d-4d87-99f7-47f6fbf90b08',
        'https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/rio2.jpg?alt=media&token=f2d9eaf8-0853-44d4-8852-e21ce230540c',
        'https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/arcoiris.jpeg?alt=media&token=c0646095-03ab-4e56-94f8-eb82ea1d47e0',
        'https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/riopdg.jpg?alt=media&token=9fc8c194-4664-43dc-9533-8d23427d40e1',
        'https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/imagenaerea.jpeg?alt=media&token=a67cdfc3-8764-44e1-85d2-f75b32beaacf',
        'https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/hotel.jpeg?alt=media&token=d4d88811-81c4-46da-9898-c8b8133e4a5a',
        'https://firebasestorage.googleapis.com/v0/b/fotospdg-169cf.appspot.com/o/pdgAlberca.jpg?alt=media&token=d8a824ce-3f32-4132-8f4c-95dd8f5515bc',

    ]

    const styles = { height: '500px', width: '100%', objectFit: 'cover'}  
    
    return(
        
        <div className='home-main'>
            
            <h1 style={{color: 'rgb(16, 110, 14)'}}>Bienvenido a Plan de Guinea, la naturaleza en su máxima expresión </h1>

           <Carrousel className='carrousel-home'
           images= {images}
           />
           
        </div>
    )
}