import 'react-day-picker/dist/style.css';
import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { makeStyles } from '@mui/styles';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { verifyReservationsInMySelectedDays, getAllRooms, submitMyPersonalReservationToReducer } from '../Redux/actions';
import Card from './Card.jsx';
import ReservationDetails from './ReservationDetails.jsx';
import './ReservationMotor.css'

const useStyles: any = makeStyles({
    dates:{
        backgroundColor: 'blue',
    }
})

export default function ReservationMotor(){
    const clases: any  = useStyles()
    const dispatch: any = useDispatch()

    const today = new Date()

    const [checkIn, setChekIn] = React.useState(new Date());
    const tomorrow = new Date(checkIn)
    tomorrow.setDate(checkIn.getDate() + 1) 

    const [checkOut, setChekOut] = React.useState(tomorrow);
    const dates = {
        checkIn: checkIn, 
        checkOut: checkOut
    }

    const days = Math.round((checkOut.getTime() - checkIn.getTime()) / 86400000)

    const theReservation = useSelector((state: any)=> state.reservationInMyDaysOfStay)

    const [edit, setEdit] = React.useState(false)

    const verifyMyReservationDays = async (dates: any)=> {   
        await dispatch(verifyReservationsInMySelectedDays(dates))  
        await dispatch(getAllRooms())
            if(!theReservation) {
            console.log(theReservation)        
            }
                setEdit(true)
    }
    const allRooms = useSelector((state: any)=> state.rooms)
    React.useEffect(()=> {
       dispatch(getAllRooms())
    }, [dispatch])

    const [submitReservation, setSubmitReservation] = React.useState(false)
    const myCheckIn = String(checkIn).slice(0, 15)
    const myCheckOut = String(checkOut).slice(0, 15)

    const handleSubmitReservation = (dates: any, rooms: any, price: Number, days: Number, _id: any)=> {
        setSubmitReservation(true)
     console.log('esto mando ', {dates, rooms, days, price})
        dispatch(submitMyPersonalReservationToReducer({dates, rooms, price, days, _id}))
    }
  const reservations = useSelector((state: any)=> state.myPersonalReservation)
    console.log('reservation', reservations)
  console.log(checkIn.getTime())

    return(        
        <div className={ reservations && reservations.length ?  'reservationReady' : 'reservationMotor reservationMotor_detailReservation' }  >
            <div className='main'> 
            <h1> Reserva al precio mas bajo </h1>

    <LocalizationProvider dateAdapter={AdapterDateFns} >
        <div style={{backgroundColor: 'white'}}>
         <DatePicker
        label="Check In"
        minDate={today}
        value={checkIn}
        onChange={(newValue: any) => {
            setChekIn(newValue);
        }}
        renderInput={ (params: any) => <TextField {...params} /> }       
      />

        <DatePicker className={clases.dates}
        label="Check Out"
        minDate={tomorrow}
        value={checkOut}
        onChange={(newValue: any) => {
            setChekOut(newValue);
        }}
        renderInput={(params: any) => <TextField {...params} />}
      />
      </div>
    </LocalizationProvider>

        <h6> Has seleccionado: { days } noches </h6>

        <Button onClick={()=> verifyMyReservationDays(dates)}> Verificar disponibilidad en las fechas seleccionadas </Button>
       
        { edit ? 
        allRooms ? 
        allRooms.map((x: any)=>  <Card 
        key={x._id}   
        description=''             
        name= {x.name}
        imagen= {x.imagen}       
        price= {x.price} 
        buttonText =  '¡Reservar este espacio!'
        buttonFunction = {()=> handleSubmitReservation({checkIn, checkOut}, x.name, x.price, days, x._id)}
        /> 
    )
    : 
    <h5> </h5>         
    : 
    null 
    } 
    </div>
        <div className='details'>
            { reservations && reservations.length ? <div className=''>           
            <ReservationDetails
            // days={reservations.days}
            // rooms={reservations.rooms}
            // price={reservations.price}
            // roomId={}
            /> </div> : null }            
        </div>
    </div>
    )
}