import axios from 'axios'

 const { REACT_APP_BACKEND_URL } = process.env //Vercel API 
// const  REACT_APP_BACKEND_URL  = `http://localhost:3001` //localhost    

export function adminLogin(email: string, password: string){
    return async function(dispatch: any){
        try {           
            const admin = {
                email: email, 
                password: password
            }
            const result = await axios.post(`${REACT_APP_BACKEND_URL}/api/users/login`, admin)
            return dispatch({
                type: "IS_ADMIN",
                payload: result
            })
             
        } catch (error: any) {
            throw new Error(error)
        }
    }
}

export async function submitNewRoom(room: Object){   
        try {          
          await axios.post(`${REACT_APP_BACKEND_URL}/api/rooms/newRoom`, room)                    
        } catch (error: any) {
            throw new Error(error)
        }   
}

export function getAllRooms(){
    return async function (dispatch: any){
        try {
            const rooms = await axios(`${REACT_APP_BACKEND_URL}/api/rooms`)
            return dispatch({
                            type: "ALL_ROOMS", 
                            payload: rooms.data
                        })
            
        } catch (error: any) {
            throw new Error(error)
        }
    }
}

export function verifyReservationsInMySelectedDays(dates: any){
    return async function (dispatch: any){
        try {
            const reservations = await axios.post(`${REACT_APP_BACKEND_URL}/api/reservations/reservationCheck`, dates)
            return dispatch({
                type: "IS_MY_ROOM_AVAILABLE",
                payload: reservations.data
            })
        } catch (error: any) {
            throw new Error(error)
        }
    }
}

export function submitMyPersonalReservationToReducer({dates, rooms, days, price, _id}: any ){
    return async function(dispatch: any){
        const reservation = { dates, rooms, days, price, roomsId: _id }
        try {  
          return dispatch({
                type: "SUBMIT_MY_PERSONAL_RESERVATION", 
                payload: reservation 
            })         
       
        } catch (error: any) {
            throw new Error(error)
        }
    }
}

export function submitMyPersonalReservationToDB({name, email, roomsId, rooms, dates, days, price,  }: any){
    return async function(dispatch: any){
        try {
            console.log('lo que se manda papu ', {name, email, dates, rooms, days, price, roomsId, })
            await axios.post(`${REACT_APP_BACKEND_URL}/api/reservations/submitReservation`, { name, email, roomsId, rooms, dates, days, price, })
        } catch (error: any) {
            // throw new Error(error)    
            console.log(error)
        }
    }
    
}

export function sendContactForm(form: any){
    return async function(dispatch: any){
        try {            
           await axios.post(`${REACT_APP_BACKEND_URL}/api/emails/recieveForm`, form)
        } catch (error: any) {
            throw new Error(error)
        }
    }
}

export function sendNewActivityToAPI(activity: Object){
    return async function(dispatch: any){
        try {
            await axios.post(`${REACT_APP_BACKEND_URL}/api/activities/addActivity`, activity)
        } catch (error: any) {
            throw new Error(error)
        }
    }
}

export function getAllActivities(){
    return async function(dispatch: any) {
        try {
            const activities = await axios(`${REACT_APP_BACKEND_URL}/api/activities`)
            return dispatch({
                            type: "ALL_ACTIVITIES", 
                            payload: activities.data
                        })   
        } catch (error: any) {
            throw new Error(error)
        }
    }
}
