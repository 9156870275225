const initialState : any = {
    isAdmin: [],
    rooms: [],
    reservationInMyDaysOfStay: [], 
    myPersonalReservation: [],
    activities: [],
}

export default function rootReducer(state = initialState, action: any){
    switch(action.type){
        case "IS_ADMIN": 
        return {
            ...state,
            isAdmin: action.payload
        }

        case "ALL_ROOMS":       
        return {
            ...state,
            rooms: action.payload
        }

        case "IS_MY_ROOM_AVAILABLE":
            return {
                ...state,
                reservationInMyDaysOfStay: action.payload
            }

        case "SUBMIT_MY_PERSONAL_RESERVATION": 
        return {
                ...state.myPersonalReservation,
                myPersonalReservation: [...state.myPersonalReservation, action.payload]
            }    

        case "ALL_ACTIVITIES":
            return {
                ...state.activities,
                activities: action.payload
            }

        default: return state
    }
    
}